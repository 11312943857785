export const navToggle = (function(){
	let my = {};

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	function _mobileToggle() {
		document.getElementById('site_nav_toggle').addEventListener('click', function(e){
			let btn = e.currentTarget;
			let nav = document.getElementById('site_header_nav');

			if(btn.classList.contains('open')) {
				btn.classList.remove('open');
				nav.classList.remove('open');
			} else {
				btn.classList.add('open');
				nav.classList.add('open');
			}
		});
	}

	function _dropdown() {
		const navItems = document.querySelectorAll('.nav_item');

		navItems.forEach((navItem) => {
			const dropdown = navItem.querySelector('.dropdown');

			if (dropdown) {
				navItem.addEventListener('click', function (e) {
					e.stopPropagation(); // Prevent parent clicks from triggering child clicks
					dropdown.classList.toggle('drop');
				});
			}
		});
	}

	my.init = function(){
		_addDoubleClick();
		_mobileToggle();
		_dropdown();
	};

	return my;
})();
