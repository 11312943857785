import { contactSubmit } from './contact.js';
import { navToggle } from './header.js';
import { helpToggle } from './help.js';
import { maps } from './map.js';
import { unitFilters } from './facility.js';
import { AutomatitCarousel } from './automatit_carousel.m.js';

navToggle.init();

helpToggle.init();

maps.init();

unitFilters.init();

// Home carousel
const initHomeCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#home_hero_caro'),
		showDots: true,
		intervalTiming: 5000,
		useChevrons: false,
		autoVerticalCentering: false,
	});
};

if(document.querySelector('#home_hero_caro')) {
	initHomeCaro();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById('facility_video')) {
	const toggleButton = document.querySelector('#toggle_video');
	const facilityVideo = document.getElementById('facility_video');
	const locationsMap = document.getElementById('locations_map');

	toggleButton.addEventListener('click', () => {
		const isVideoActive = facilityVideo.classList.contains('active');

		toggleButton.classList.toggle('active');
		facilityVideo.classList.toggle('active');
		locationsMap.classList.toggle('active');
		
		if (isVideoActive) {
			toggleButton.textContent = 'Video';
		} else {
			toggleButton.textContent = 'Map';
		}
	});
}
