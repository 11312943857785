export const unitFilters = (function(){
	let my = {},
		size = null;

	function _filterUnits(){
		let noUnits = document.getElementById('no_units');
		noUnits.style.display = "block";
		[].forEach.call(document.querySelectorAll('#unit_table .unit_row'), (el) => {
			if(size === null){
				el.style.display = 'table-row';
				noUnits.style.display = "none";
			}else{
				let props = el.dataset.prop;
				el.style.display = 'none';
				if(props.includes(size)){
					el.style.display = 'table-row';
					noUnits.style.display = "none";
				}
			}
		});
	}

	function _toggleFilters({currentTarget}){
		if(currentTarget.dataset.filter === "all"){
			[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
				el.classList.remove("active");
			});
			size = null;
			currentTarget.classList.add('active');
		}else{
			let active = currentTarget.parentElement.querySelector('.active');
			active.classList.remove('active');
			currentTarget.classList.add('active');
			size = currentTarget.dataset.filter;
		}

		_filterUnits();
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
			el.addEventListener('click', _toggleFilters);
		});
	}

	my.init = () => {
		if(document.getElementById('facility_size_filters') || document.getElementById('facility_info_toggle')){
			_assignListeners();
		}
	};

	return my;
})();
